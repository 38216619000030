<template>
  <div>
    <CRow>
      <CCol class="client-create">
        <CCard class="">
          <CCardBody class="">
            <CForm>
              <h1>取引先新規登録</h1>
              <div class="input-box">
                <div class="">
                  <div>取引先名（必須）</div>
                  <input
                    v-model="clientData.companyName.name"
                    class="middle-input"
                    type="text"
                    placeholder="eatas株式会社"
                  />
                </div>
              </div>
              <div class="input-box">
                <div class="">
                  <div>ヨミガナ（必須）</div>
                  <input
                    v-model="clientData.companyName.ruby"
                    class="middle-input"
                    type="text"
                    placeholder="イータスカブシキガイシャ"
                  />
                </div>
              </div>
              <!-- <div class="input-box">
                <div class="small-input">
                  <div>責任者名</div>
                  <div>名前：性</div>
                  <input
                    v-model="clientData.chiefAdministrator.lastName"
                    class=""
                    type="text"
                    placeholder="山田"
                  />
                </div>
                <div class="small-input">
                  <div>名</div>
                  <input
                    v-model="clientData.chiefAdministrator.firstName"
                    class=""
                    type="text"
                    placeholder="太郎"
                  />
                </div>
              </div>
              <div class="input-box">
                <div class="small-input">
                  <div>名前：セイ</div>
                  <input
                    v-model="clientData.chiefAdministrator.lastNameRuby"
                    class=""
                    type="text"
                    placeholder="ヤマダ"
                  />
                </div>
                <div class="small-input">
                  <div>メイ</div>
                  <input
                    v-model="clientData.chiefAdministrator.firstNameRuby"
                    class=""
                    type="text"
                    placeholder="タロウ"
                  />
                </div>
              </div>
              <div class="input-box">
                <div class="">
                  <div>メールアドレス</div>
                  <input
                    v-model="clientData.chiefAdministrator.email"
                    class="middle-input"
                    type="email"
                    placeholder="example@example.com"
                  />
                </div>
              </div> -->
              <div class="input-box">
                <div class="">
                  <div>取引先認証用コード設定（必須）</div>
                  <input v-model="authenticationCode" class="middle-input" type="text" placeholder="" />
                  <div class="">ランダムに数を生成しております。任意の数字でも可。</div>
                </div>
              </div>
              <CButton color="success" @click="addClient">新規登録</CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import firebase from "firebase";
const db = firebase.firestore();
export default {
  name: "BusinessPartnerRegistration",
  data() {
    return {
      clientID: 1,
      getClientNumber: 1,
      clientData: {
        companyName: {
          name: "",
          ruby: "",
        },
      },
      authenticationCode: "",
    };
  },
  computed: {},
  created() {
    this.authenticationCode = this.getPassword();
  },
  methods: {
    getPassword(length = 10) {
      let password_base = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let password = "";
      for (let i = 0; i < length; i++) {
        password += password_base.charAt(Math.floor(Math.random() * password_base.length));
      }
      return password;
    },
    async addClient() {
      // 最新のclientIDをとってきて+1する
      await db
        .collection("clients")
        .orderBy("createdAt", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // console.log(doc.data());
            this.getClientNumber = doc.data().clientID + 1;
          });
        });
      await db
        .collection("clients")
        .doc(`${this.getClientNumber}`)
        .set({
          clientID: this.getClientNumber,
          clientData: this.clientData,
          authenticationCode: this.authenticationCode,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      this.$router.push("client-list");
    },
  },
};
</script>
<style scoped>
.name {
  display: flex;
}

input {
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #768192;
}
input::placeholder {
  color: #bbb;
}

input[type="radio"] {
  margin: 5px;
}

label {
  margin-right: 2rem;
  margin-bottom: 0px;
}

.small-input {
  margin-right: 1rem;
}

.input-box {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: flex-end;
}

.middle-input {
  width: 300px;
}
.course select {
  width: 300px;
  border: solid 1px #d8dbe0;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  color: #3c4b64;
}
</style>
